
import Vue from "@/interfaces/vue";
import { Component } from "vue-property-decorator";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
import { post } from "@/api";
import { HcaptchaElement } from "@/interfaces/misc";

@Component({ components: { VueHcaptcha } })
export default class Newsletter extends Vue {
  email = "";
  siteKey = process.env.VUE_APP_SITE_KEY;

  async submit(captcha_token: string) {
    await post.newsletterSignUp(this.email, captcha_token);
  }

  validate() {
    (this.$refs.hcaptcha as HcaptchaElement).execute();
  }

  onCaptchaExpired() {
    (this.$refs.hcaptcha as HcaptchaElement).reset();
  }
}
