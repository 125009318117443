
import Vue from "@/interfaces/vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class ContentColumn extends Vue {
  @Prop({ default: "none" })
  backgroundColor!: string;

  @Prop({ default: "column" })
  layout!: string;
}
